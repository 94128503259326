import React from "react";
import "./Blog.css";
import { Link, useNavigate } from "react-router-dom";
import { EditorState, convertFromRaw,ContentState  } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Blogitem(props) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  };
  return (
    // Blogitem with right image
    <div>
      <div className="row featurette">
        {props.position === "left" ? (
          <div className="col-lg-5 col-md-6" style={{height:'150px'}}>
            {props.is_video ? (
              <video
                controls
                style={{height: '-webkit-fill-available', objectFit: 'cover' }}
              >
                <source src={props.video} type="video/mp4" />
              </video>
            ) : (
              <img
                src={props.Img}
                className="featurette-image img-fluid mx-auto"
                style={{height: '-webkit-fill-available', width: '-webkit-fill-available', objectFit: 'contain'}}
              />
            )}
          </div>
        ) : (
          ""
        )}
        <div className="col-lg-7 col-md-6 blogitem">
          <Link to={`/Blogdetails/${props.id}`} className="text-decoration-none">
            <h4
              className={`featurette-heading fw-bold ${process.env.REACT_APP_TEXT_COLOR} lh-1`}
            >
              {props.title}
            </h4>
          </Link>
          <div className="container">
            <div className="row d-flex blogitemrow">
              <div className="col-12 col-md-7 d-flex">
                <div className="col-1 col-md-1 p-0 pe-2">
                  <i
                    className={`bi bi-person-fill ${process.env.REACT_APP_TEXT_COLOR}`}
                  ></i>
                </div>
                <div className="col-1 col-md-1 p-0 me-auto">
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} text-start`}>
                    {props.author}
                  </p>
                </div>
                <div className="col-1 col-md-1 p-0 pe-2">
                  <i
                    className={`bi bi-calendar-date ${process.env.REACT_APP_TEXT_COLOR}`}
                  ></i>
                </div>
                <div className="col-md-3 col-3 p-0">
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} text-start`}>
                  {formatDate(props.created_at)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className={`lead description ${process.env.REACT_APP_TEXT_COLOR}`}>
            {props.description}
            {/* <Editor
              editorState={props.description}
              readOnly={true}
                toolbarHidden={true}
            /> */}
           
            <Link
              to={`/Blogdetails/${props.id}`}
              className="me-auto"
              style={{ color: process.env.REACT_APP_READ_MORE_TEXT_COLOR }}
            >
              Read More
            </Link>
          </p>
        </div>
        {props.position === "right" ? (
          <div className="col-lg-5 col-md-6 col-12" style={{height:'150px'}}>
            {props.is_video ? (
              <video
                controls
                style={{height: '-webkit-fill-available',
                  objectFit: 'cover' }}
              >
                <source src={props.video} type="video/mp4" />
              </video>
            ) : (
              <img
                src={props.Img}
                className="featurette-image img-fluid mx-auto"
                style={{height: '-webkit-fill-available', width: '-webkit-fill-available', objectFit: 'contain'}}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
