import React, { useState, useEffect } from "react";
import { getCookie } from "../utils/Utils";
import { Link, useParams } from "react-router-dom";
import { ContentState, Editor, EditorState, convertFromRaw } from "draft-js";
import "../assets/css/blog.css";
import Podcast from "./Podcast";
import WaveForm from "./user/WaveForm";
import PlayList from "./user/PlayList";
import demoAudio from "../track/track1.mp3"; 
import "../assets/css/style.css";



export default function Blogdetailcard(props) {
  // const des = props.Blogdesc.split("\n");
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [des, setDes] = useState("");
  const isVideo = props.Blogimg && props.Blogimg.match(/\.(mp4|ogg|webm)$/);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateShortDesc, setEditorStateShortDesc] = useState(EditorState.createEmpty());
  const [editorStateFullDesc, setEditorStateFullDesc] = useState(EditorState.createEmpty());
  const [audioFilePath, setAudioFile] = useState(demoAudio);

  //show the audio file
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-blog`;
        const formData = new FormData();

        formData.append("id", id);
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setBlog(data.data?.blogs);
            const audioUrl = data.data.blogs.blog_audio;
            setAudioFile(audioUrl || demoAudio);
          } else {
            console.error("Error fetching blog data", data.message);
          }
        } else {
          console.error("Error fetching blog data", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchBlogData();
  }, []);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-blog`;
        const formData = new FormData();

        formData.append("id", id);
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setBlog(data.data?.blogs);
            // const contentState = convertFromRaw(
            //   JSON.parse(data.data?.blogs.description)
            // );
            // setEditorState(EditorState.createWithContent(contentState));
            const contentRaw = JSON.parse(data.data?.blogs.description);
            const contentState = convertFromRaw(contentRaw);
            const blocks = contentState.getBlocksAsArray();
            let shortDescBlocks = [];
            let fullDescBlocks = [];
            let charCount = 0;

            for (const block of blocks) {
              if (charCount + block.getText().length <= 300) {
                shortDescBlocks.push(block);
                charCount += block.getText().length;
              } else {
                const remainingChars = 300 - charCount;
                if (remainingChars > 0) {
                  const shortBlock = block.merge({
                    text: block.getText().slice(0, remainingChars),
                  });
                  shortDescBlocks.push(shortBlock);
                  const fullBlock = block.merge({
                    text: block.getText().slice(remainingChars),
                  });
                  fullDescBlocks.push(fullBlock);
                } else {
                  fullDescBlocks.push(block);
                }
                break;
              }
            }

            fullDescBlocks = fullDescBlocks.concat(blocks.slice(shortDescBlocks.length));

            const shortDescContentState = ContentState.createFromBlockArray(shortDescBlocks);
            setEditorStateShortDesc(EditorState.createWithContent(shortDescContentState));

            const fullDescContentState = ContentState.createFromBlockArray(fullDescBlocks);
            setEditorStateFullDesc(EditorState.createWithContent(fullDescContentState));

          } else {
            console.error("Error fetching blog data", data.message);
          }
        } else {
          console.error("Error fetching blog data", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    const fetchRecentPosts = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-list-blog`;
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setRecentPosts(data.data);
          } else {
            console.error("Error fetching recent posts", data.message);
          }
        } else {
          console.error("Error fetching recent posts", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchBlogData();
    fetchRecentPosts();
  }, [id]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString)
      .toLocaleDateString("en-GB", options)
      .replace(/\//g, "-");
  };
  const [categoryName, setCategoryName] = useState("");
  
  useEffect(() => {
    // if(!blog) return;
    if (blog) {
      const categoryId = blog.categories;
      if (categoryId === "TECH TRENDS") {
        setCategoryName("Tech Trends");
      } else if (categoryId === "ARTIFICIAL INTELLIGENCE") {
        setCategoryName("Artificial Intelligence");
      } else if (categoryId === "PRODUCT REVIEWS") {
        setCategoryName("Product Reviews");
      } else if (categoryId === "CLOUD COMPUTING") {
        setCategoryName("Cloud Computing");
      } else if (categoryId === "TECH NEWS") {
        setCategoryName("Tech News");
      } else if (categoryId === "TECH CONFERENCES") {
        setCategoryName("Tech Conference");
      } else {
        setCategoryName("");
      }
    }
  }, [blog]);
  return (
    <div>
      <div
        className="card"
        style={{
          backgroundColor: process.env.REACT_APP_BLOGDETAILS_BACKGROUND_COLOR,
        }}
      >
        <div className="card-body">
          <div className="card-header border-0 text-start m-0 px-2">
            <span
              className="px-3 py-2 rounded"
              style={{
                borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`,
                backgroundColor:
                  process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR,
              }}
            >
              {blog && (
                <Link
                  className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}
                  style={{ textAlign: "justify" }}
                >
                  {categoryName}
                  
                </Link>
              )}
            </span>
          </div>
          <h3
            className={`card-title fw-bold text-start m-2  ${process.env.REACT_APP_TEXT_COLOR}`}
          >
            {props.Blogtitle}
          </h3>
          <div className="blog-detail-image">
            {props.is_video ? (
              <video controls className="card-img rounded-0">
                <source src={props.BlogVideo} type="video/mp4" />
              </video>
            ) : (
              <img src={props.Blogimg} className="card-img rounded-0" style={{height: '-webkit-fill-available',width: '-webkit-fill-available', objectFit:'contain',maxHeight:'500px'}} />
            )}
          </div>

          <div className="card-body row  justify-content-between">
            {blog && (
              <div className="recent-post d-flex">
                <div className="col-6">
                  <p
                    className={`${process.env.REACT_APP_TEXT_COLOR} text-start me-auto`}
                  >
                    <i
                      className={`bi bi-person-fill ms-2 ${process.env.REACT_APP_TEXT_COLOR}`}
                    ></i>
                    <span className="ms-2">{blog.author}</span>
                  </p>
                </div>
                <div className="col-6">
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} text-end`}>
                    <i
                      className={`bi bi-calendar-date  ms-2 ${process.env.REACT_APP_TEXT_COLOR}`}
                    ></i>
                    <span className="ms-2">{formatDate(blog.created_at)}</span>
                  </p>
                </div>
              </div>
            )}
          </div>
          {editorStateShortDesc && (
              <Editor
                readOnly={true}
                editorState={editorStateShortDesc}
                toolbarHidden={true}
                style={{ textAlign: 'left' }}
              />
            )}
            {/* <Podcast /> */}
            <WaveForm url={audioFilePath} />
            <PlayList audioFilePath={audioFilePath} />
            {editorStateFullDesc && (
              <Editor
                readOnly={true}
                editorState={editorStateFullDesc}
                toolbarHidden={true}
                style={{ textAlign: 'left' }}
              />
            )}
        </div>
      </div>
    </div>
  );
}
