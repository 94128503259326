import React, { useState, useCallback, useEffect } from "react";
import "boxicons/css/boxicons.min.css";
import ReactWaves from "@dschoon/react-waves";
import demoAudio from "../track/track1.mp3"; 
import { getCookie } from "../utils/Utils";
import { useParams } from "react-router-dom";
import arrowb from "../images/arrowb.png";
import arrowf from "../images/arrowf.png";
import "../../src/App.css";
import { Icon } from "./Component";

const Waveaudio = () => {
  const { id } = useParams();
  const [wavesurfer, setWavesurfer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [pos, setPos] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [blog, setBlog] = useState(null);
  const [audioFilePath, setAudioFile] = useState(demoAudio);
  const onLoading = useCallback(({ wavesurfer, originalArgs = [] }) => {
    setLoaded(originalArgs[0] === 100);
    setWavesurfer(wavesurfer);
  }, []);

  const onPosChange = useCallback((pos, wavesurfer) => {
    setPos(pos);
    setWavesurfer(wavesurfer);
  }, []);

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("ready", () => {
        setDuration(wavesurfer.getDuration());
      });
    }
  }, [wavesurfer]);

  // Function to format time in MM:SS format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const togglePlay = () => {
    setPlaying(!playing);
  };

  // const toggleSpeed = () => {
  //   const newSpeed = speed === 1 ? 1.5 : 1;
  //   setSpeed(newSpeed);
  //   if (wavesurfer) {
  //     wavesurfer.setPlaybackRate(newSpeed);
  //   }
  // };
  const toggleSpeed = () => {
    const speeds = [1, 1.25, 1.5, 2];
    const currentIndex = speeds.indexOf(speed);
    const newSpeed = speeds[(currentIndex + 1) % speeds.length];
    setSpeed(newSpeed);
    if (wavesurfer) {
      wavesurfer.setPlaybackRate(newSpeed);
    }
  };

  // Function to skip backward
  const skipBackward = () => {
    if (wavesurfer) {
      wavesurfer.skipBackward(15);
    }
  };

  // Function to skip forward
  const skipForward = () => {
    if (wavesurfer) {
      wavesurfer.skipForward(15);
    }
  };

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-blog`;
        const formData = new FormData();

        formData.append("id", id);
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setBlog(data.data?.blogs);
            const audioUrl = data.data.blogs.blog_audio;
            setAudioFile(audioUrl || demoAudio);
          } else {
            console.error("Error fetching blog data", data.message);
          }
        } else {
          console.error("Error fetching blog data", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchBlogData();
  }, []);

  const shareToFacebook = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Check out this audio blog!',
        text: 'I found this audio blog interesting, have a look!',
        url: window.location.href,
      }).catch((error) => console.error('Error sharing', error));
    } else {
      console.error('Share not supported on this browser');
    }
  };
  return (
    <div className="container podcast-audio">
      <div className=" d-flex align-items-center">
        <div className="play button" onClick={togglePlay}>
          {!playing ? (
            <div className="play-pause">
              <i class="bx bx-play fs-1"></i>
            </div>
          ) : (
            <div className="play-pause">
              <i class="bx bx-pause fs-1"></i>
            </div>
          )}
        </div>

        <ReactWaves
          audioFile= {audioFilePath}  
          options={{
            barGap: 3,
            barWidth: 2,
            barHeight: 2,
            cursorWidth: 1,
            height: 80,
            hideScrollbar: true,
            progressColor: "#EC407A",
            normalize: true,
            responsive: true,
            waveColor: "#D1D6DA",
          }}
          volume={1}
          zoom={1}
          pos={pos}
          playing={playing}
          onPosChange={onPosChange}
          onLoading={onLoading}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <button onClick={skipBackward} className="border-0 bg-transparent">
            <img src={arrowb} alt="" />
          </button>
          <button onClick={skipForward} className="border-0 bg-transparent mx-1">
            <img src={arrowf} alt="" />
          </button>
          <button
            className="border-0 bg-transparent fw-bold fs-5 mx-4"
            onClick={toggleSpeed}
          >
            {/* {speed === 1 ? "1x" : "1.5x"} */}
            {speed}x
          </button>
          <p className="mb-0 mx-3 ">More Info</p>
          {/* <p className="mb-0 ">Share</p> */}
          <p className="mb-0 pt-1" id="cuadro" onClick={shareToFacebook} style={{cursor:'pointer'}}><Icon name="share-fill fs-4"></Icon></p>
        </div>
        <div className="text-end opacity-75">
          {formatTime(pos)} | {formatTime(duration)}
        </div>
      </div>

      {/* <div id="timeline" style={{ width: "650px", margin: "0 auto" }} /> */}
    </div>
  );
};

export default Waveaudio;
