export const linkItems = [
    {
      link: `${process.env.REACT_APP_ACCOUNT_URL}apps`,
      icon: 'list',
      text: 'Apps',
    },
    {
      link: `${process.env.REACT_APP_ACCOUNT_URL}Account Setting`,
      icon: 'setting-alt',
      text: 'Account Setting'
    },
  ];
  