import React from "react";
import { Button } from "../Component";
import Icon from "../../components/icon/Icon";
import { useNavigate } from "react-router";

const ViewDetailButton = ({ row }) => {
  const navigate = useNavigate(); 

  const handleButtonClick = () => {
    navigate("../user-details/" + row.hash_key);
  };

  return (
    <Button className="btn-dim" color="primary" onClick={handleButtonClick}>
      <Icon name="view-x3" />
      <span>View Details</span>
    </Button>
  );
};

export default ViewDetailButton;
