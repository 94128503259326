import React from "react";
import bh from "../images/bh.png";
import Waveaudio from "./Waveaudio1";

const Podcast = () => {
  return (
    <>
      <section className="section-podcast text-start">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div>
                <h5>
                  #24 Story Session - Why midlife is the perfect time to "start"
                </h5>
                <small className="text-success">The Midlife Feast</small>
                <div className="row">
                  <div className="col-md-3 align-items-center">
                    <div className="" style={{height: '100%'}}>
                      <img src={bh} alt="blogheadimg" className="img w-100" style={{height:' -webkit-fill-available', objectFit: 'cover'}} /> 
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Waveaudio />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Podcast;
