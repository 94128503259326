import React, { useEffect, createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CategoryContext } from "../utils/Context";
export const GloabalContext = createContext();
const Layout = ({ title, app, ...props }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  return (
    <>
      <GloabalContext.Provider value={{ files, setFiles }}>
        <Head title={!title && "SSOGH"} />
        <CategoryContext.Provider value={[selectedCategory, setSelectedCategory]}>
          <ToastContainer hideProgressBar autoClose={3000} />
          {/* <AppRoot>
            <AppMain> */}
              <Outlet />
            {/* </AppMain>
          </AppRoot> */}
        </CategoryContext.Provider>
      </GloabalContext.Provider>
    </>
  );
};
export default Layout;
